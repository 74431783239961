<template>
    <div class="wap-box">
        <to-history :prop-message="title"></to-history> 
        <div class="count flex">
            <h4>订单分析</h4>
            <div>
                <a-radio-group v-model="choose" @change="chooseChange">
                    <a-radio-button v-for="(item,index) in chooseArr" :key="index" :value="item.value">
                        {{item.label}}
                    </a-radio-button>
                </a-radio-group>
            </div>
        </div>
        <div class="count flex" style="margin:20px 0;align-items: center">
            <a-select v-model="allValue" @change="timeChange(allValue, 1)">
                <a-select-option
                    v-for="(item, index) in timeArr"
                    :key="index"
                    :value="item.value"
                    >{{ item.label }}</a-select-option
                >
            </a-select>
            <a-date-picker class="head_date" v-model="timeStart" @change="onChange()"/>至<a-date-picker class="head_date" v-model="timeEnd" @change="onChange()"/>
            <!-- 日期: 
            <a-radio-group v-model="value" @change="timeChange(value)">
                <a-radio-button v-for="(item,index) in timeArr" :key="index" :value="item.value">
                    {{item.label}}
                </a-radio-button>
            </a-radio-group><a-range-picker @change="onChange"></a-range-picker> -->
        </div>
        <div class="pannel">
            <div class="flex countTitle">
                <h4>订单数据</h4>
            </div>
            <div >
                <div id="orderNew" class="datas-common"></div>
                <div id="orderReplenish" class="datas-common"></div>
                <div id="orderPayment" class="datas-common"></div>
                <div id="orderRefund" class="datas-common"></div>
                <div id="orderRefundPie" class="datas-common"></div>
                <div id="orderNewPie" class="datas-common"></div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import {requestXml} from '../../../assets/js/request';
var moment = require('moment');
export default {
    name: 'envyPie',
    props: {
        voltage: Array
    },
    data() {
        return {
            start: "",
            end: "",

            allValue: 3,
            timeArr: [
                {"label":"今日","value": 1},
                {"label":"本周","value": 2},
                {"label":"本月","value": 3},
                {"label":"全年","value": 4},
            ],
            timeStart: null,
            timeEnd: null,
            choose: "D",
            chooseArr: [
                {"label":"按日","value": "D"},
                {"label":"按周","value": "W"},
                {"label":"按月","value": "M"}
            ],
            title:this.$route.query.title
        }
    },
    mounted: function() {
        this.start = moment().startOf('month').format('x');
        this.end =  moment().endOf('month').format('x');
        // 获取客户分析数据
        this.getPannel();
    },
    methods: {
        // 设置开始时间0:0:0 结束时间23:59:59
        setTime(time,type){
            if(type == 1){
                time.setHours(0);
                time.setMinutes(0);
                time.setSeconds(0);
            }else{
                time.setHours(23);
                time.setMinutes(59);
                time.setSeconds(59);
            }
            return time.getTime();
        },
        
        // window.console.log(moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'));
        // window.console.log(moment().startOf('day').format('X'));//以10位Unix时间戳输出(秒）
        // window.console.log(moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'));
        // window.console.log(moment().endOf('day').format('x'));//以13位Unix时间戳输出（毫秒）
        timeChange(val){
            if(val == 1){
                this.start = moment().startOf('day').format('x');
                this.end =  moment().endOf('day').format('x');
            }else if(val == 2){
                this.start = moment().startOf('week').add(1,'day').format('x');
                this.end =  moment().endOf('week').add(1,'day').format('x');
            }else if(val == 3){
                this.start = moment().startOf('month').format('x');
                this.end =  moment().endOf('month').format('x');
            }else{
                this.start = moment().startOf('year').format('x');
                this.end =  moment().endOf('year').format('x');
            }
            this.timeStart='';
            this.timeEnd='';
            this.getPannel();
        },
        onChange() {
            if(this.timeStart){
                this.start = moment(this.timeStart).startOf('day').format("x");
            }else{
                this.start='';
            }
            if(this.timeEnd){
                this.end = moment(this.timeEnd).endOf('day').format("x");
            }else{
                this.end='';
            }
            this.getPannel();
            
        },
        chooseChange(){
            this.getPannel();
        },
        getPannel(){
            requestXml("/scrm/Statistics/getOrder","POST",(res) => {
                // 订单
                this.drawLineChart("新增订单表",res.order_new_log[0],res.order_new_log[1],"orderNew");
                this.drawLineChart("新增补充",res.replenish_new_log[0],res.replenish_new_log[1],"orderReplenish");
                this.drawLineChart("新增收款",res.payment_new_log[0],res.payment_new_log[1],"orderPayment");
                this.drawLineChart("新增退款",res.refund_new_log[0],res.refund_new_log[1],"orderRefund");
                
                this.drawPieChart("退款类型分析",res.refund_type,"orderRefundPie");

                let orderAll = Number(res.all.num);
                let orderNewArr = [];
                orderNewArr[0] = {"name": "原有订单" ,"value": orderAll - Number(res.new.num)};
                orderNewArr[1] = {"name": "新增订单" ,"value": res.new.num};
                this.drawPieChart("新增订单数据",orderNewArr,"orderNewPie");

                // let corpUnassignedArr = [];
                // corpUnassignedArr[0] = {"name": "已分配客户数" ,"value": corAll - Number(res.corp.unassigned)};
                // corpUnassignedArr[1] = {"name": "未分配客户数" ,"value": res.corp.unassigned};
                // this.drawPieChart("未分配客户数据",corpUnassignedArr,"corpUnassignedPie");

                // let corpOrderokArr = [];
                // corpOrderokArr[0] = {"name": "未成交客户数" ,"value": corAll - Number(res.corp.order_ok)};
                // corpOrderokArr[1] = {"name": "成交客户数" ,"value": res.corp.order_ok};
                // this.drawPieChart("成交客户数据",corpOrderokArr,"corpOrderokPie");

                // this.drawPieChart("客户来源分类",res.corp.origin_type,"corpOriginPie");
                // for(let i=0;i<res.corp.follow_type.length;i++){
                //     res.corp.follow_type[i].name = this.followArr[Number(res.corp.follow_type[i].name)-1]
                // }
                // this.drawPieChart("跟进记录分类",res.corp.follow_type,"corpFollowPie");


                
                
            },{"start": this.start,"end": this.end,"timetype": this.choose})
        },
        drawLineChart(name,xData,allData,ele) {
            var myChart = echarts.init(document.getElementById(ele));
            // 绘制图表
            myChart.setOption({
                title: {
                    text: name
                },
                tooltip: {},
                xAxis: {
                    data: xData
                },
                yAxis: {},
                series: [{
                    name: "",
                    type: "line",
                    data: allData
                }]
            });
        },
        drawPieChart(pieName,pieAllData,pieEle) {
            var pieChart = echarts.init(document.getElementById(pieEle));
            pieChart.setOption({
                title: {
                    text: pieName,
                    // subtext: '纯属虚构',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                },
                series: [{
                    name: "",
                    type: 'pie',
                    radius: '50%',
                    data: pieAllData,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }]
            });
        },
        drawFunnelChart(funnelName,funnelAllData,funnelEle) {
            var funnelChart = echarts.init(document.getElementById(funnelEle));
            funnelChart.setOption({
                title: {
                    text: funnelName,
                },
                tooltip: {
                    trigger: 'item',
                    formatter: "{a} <br/>{b} : {c}%"
                },
                legend: {},
                series: [{
                    name: "",
                    type:'funnel',
                    left: '10%',
                    top: 60,
                    //x2: 80,
                    bottom: 60,
                    width: '80%',
                    // height: {totalHeight} - y - y2,
                    min: 0,
                    max: 100,
                    minSize: '0%',
                    maxSize: '100%',
                    sort: 'descending',
                    gap: 2,
                    label: {
                        show: true,
                        position: 'inside'
                    },
                    labelLine: {
                        length: 10,
                        lineStyle: {
                            width: 1,
                            type: 'solid'
                        }
                    },
                    itemStyle: {
                        borderColor: '#fff',
                        borderWidth: 1
                    },
                    emphasis: {
                        label: {
                            fontSize: 20
                        }
                    },
                    data: funnelAllData
                }]
            })
        }
    },

}
</script>
<style>
    
</style>
